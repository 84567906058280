import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// /v4/articles/{id}/
export default function BlogDetail() {
  const params = useParams();
  const [article, setArticle] = useState({});
  const [loading, setLoading] = useState(true);
  const [notFoud, setNotfound] = useState(false);

  useEffect(
    function () {
      async function getArtikel() {
        const request = await fetch(
          `https://api.spaceflightnewsapi.net/v4/articles/${params.id}`
        );
        if (!request.ok) {
          setLoading(false);
          return setNotfound(true);
        }
        const response = await request.json();

        document.title = response.title;
        setArticle(response);
        setLoading(false);
      }
      getArtikel();
    },
    [params]
  );
  if (notFoud) {
    return <h1>Artikel tidak ditemukan :C</h1>;
  }
  return (
    <>
      <section className="section">
        {loading ? (
          <i>Loading artikel ...</i>
        ) : (
          <article className="article">
            <h1 className="article-title">{article.title}</h1>
            <time className="article-time">
              {new Date(article.published_at).toLocaleDateString()}
            </time>
            <img src={article.image_url} className="article-image" />
            <p className="article-summary">{article.summary}</p>
            <p className="article-source">
              Source :{" "}
              <a href={article.url} target="_blank" rel="noreferrer">
                {article.news_site}
              </a>
            </p>
          </article>
        )}
      </section>
    </>
  );
}
