import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Blog() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(function () {
    document.title = "Blog Page";
    async function getArtikel(params) {
      const request = await fetch(
        "https://api.spaceflightnewsapi.net/v4/articles/"
      );
      const response = await request.json();

      setArticles(response.results);
      setLoading(false);
    }
    getArtikel();
  }, []);

  return (
    <>
      <section className="section">
        <h1 className="section-title">Blog</h1>
        <p className="section-description">
          Berikut ini merupakan beberapa tulisan saya
        </p>
        {loading && <i>Loading article ....</i>}
        {!loading && (
          <div className="article">
            {articles.map(function (article) {
              return (
                <article key={article.id} className="article">
                  <h2 className="article-title">
                    <Link to={`/blog/${article.id}`}>{article.title}</Link>
                  </h2>
                  <time className="article-time">
                    {new Date(article.published_at).toLocaleDateString()}
                  </time>
                </article>
              );
            })}
          </div>
        )}
      </section>
    </>
  );
}
